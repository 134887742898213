<template>
  <form action="#" class="form" data-gw-tab="languages" data-gw-tab-grp="form">
    <div class="field field_grp field_mb" translate="no">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__flag.png" alt="" />
        </div>
        <div class="field__title" translate="yes">Language</div>
      </div>
      <select
        class="tt-uppercase notranslate"
        ref="dataSelectLanguage"
        data-select-language
        v-model="flag"
      >
        <template v-for="(language, i) in languages" :key="i">
          <option :value="language.imgSuffix" :data-img="language.imgSuffix">
            {{ language.name }}
          </option>
        </template>
      </select>
    </div>
    <div class="form__btn-box">
      <a href="#" class="button" data-btn-language @click.prevent="dataBtnLangClick">
        <span class="button__content">Далее</span>
      </a>
    </div>
  </form>
</template>

<script>
import { __config } from '@/assets/js/config';
const $ = require('jquery');

export default {
  name: 'LanguageForm',
  data() {
    return {
      sel_lang: null,
    };
  },
  computed: {
    languages: {
      get() {
        return this.$store.getters.get_languages;
      },
    },
    lang: {
      get() {
        try {
          return JSON.parse(localStorage.getItem('yt-widget')).lang;
        } catch (e) {
          console.log('localStorage/cookies - is not allowed, fetch default lang');
          return this.$store.getters.get_lang;
        }
      },
      set(value) {
        this.sel_lang = value;
      },
    },
    flag: {
      get() {
        return this.$store.getters.get_flag;
      },
    },
  },
  mounted() {
    $('[data-select-language]')
      .select2({
        language: this.sel_lang || __config.translate.lang,
        containerCssClass: 'slanguage',
        dropdownCssClass: 'olanguage',
        templateResult: formatState,
        templateSelection: formatState,
      })
      .on('select2:open', () => {
        $('.select2-container').attr('translate', 'no');
      })
      .on('select2:selecting', e => {
        this.sel_lang = e.params.args.data.id;
      });

    function formatState(state) {
      if (!state.id) {
        return state.text;
      }

      return $(
        '<div class="olanguage__item"><img src="' +
          __config.translate.folder +
          '/lg__' +
          state.element.dataset.img +
          '.png" class="img-flag" alt="' +
          state.element.value +
          '"/><div class="olanguage__txt">' +
          state.text +
          '</div></div>'
      );
    }
  },
  methods: {
    dataBtnLangClick() {
      let flag = this.sel_lang;
      if (this.sel_lang === 'bras') {
        this.sel_lang = 'pt';
        flag = 'bras';
      }
      if (this.sel_lang === 'usa') {
        this.sel_lang = 'en';
        flag = 'usa';
      }

      this.$store.dispatch('update_lang', this.sel_lang);
      this.$store.dispatch('update_flag', flag);
      window.location.reload();
      return false;
    },
  },
};
</script>

<style scoped></style>
